import { EMPTY_SEARCH_MODE_ENTITY, SearchModeEntity } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { DeliveryChargeSummaryEntity } from "domain/entity/ChargeHeader/DeliveryChargeSummaryEntity";
import { DomUtils } from "helpers/DomUtils";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customEventListener } from "helpers/Events";
import { ChargeDataSearchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA } from "presentation/constant/ChargeDataEnquiry/ChargeDataSearchCriteria";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { MicroFrontend } from "presentation/view/components/workspace/MicroFrontend/MicroFrontend";
import { ChargeDataSearchTablePanel } from "presentation/view/section/ChargeDataEnquiry/ChargeDataTablePanel/ChargeDataSearchTablePanel";
import { useEffect, useMemo, useState } from "react";
import { Loader, ResizablePanel } from "veronica-ui-component/dist/component/core";
import { ChargeDataSearchRightPanel } from "./ChargeDataSearchPanel/ChargeDataSearchRightPanel";
import { DeletePreferenceModal } from "./ConfirmModal/DeletePreferenceModal";
import { SaveNewPreferenceModal } from "./ConfirmModal/SaveNewPreferenceModal";

export function isSearchCriteriaSet(obj1: any, obj2: any) { return JSON.stringify(obj1) === JSON.stringify(obj2); }

export const ChargeDataSearchComp: React.FC = () => {
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const searchCounter = chargeDataSearchState.searchCounter;
    // const openedContainerNo = inventorySearchState.openedContainerNo;
    const isShowContainerDetail = chargeDataSearchState.isShowContainerDetail;
    const isEditSearchCriteria = chargeDataSearchState.isEditSearchCriteria;
    const isShowRightCriteriaPanel = chargeDataSearchState.isShowRightCriteriaPanel;
    const [anaInfoState] = useANAInfoTracked();
    const [initinal, setInitial] = useState<boolean>(true);

    // const memoContainerDetailModal = useMemo(() => openedContainerNo ? <ContainerDetail/> : null, [openedContainerNo]);
    const chargeDataSearchVM = useChargeDataSearchVM();

    useEffect(() => {
        const onChargeItemRedirect = async (e: any) => {
            switch (e.detail.action) {
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY:
                    const deliverySummary = (e.detail.data.data as DeliveryChargeSummaryEntity)
                    localStorage.setItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY, JSON.stringify(deliverySummary));
                    setInitial(true);

                    break;
            }
        };

        customEventListener(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT_INITIAL, onChargeItemRedirect);
    }, [chargeDataSearchVM]);

    useEffect(() => {
        if (initinal) {
            if (!localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY)) {
                DomUtils.addOnlySearchClass();
                setInitial(false);
            } else {
                chargeDataSearchVM.showLoading();

                DomUtils.addOnlySearchClass();

                const timerAddCss = setTimeout(() => {
                    chargeDataSearchVM.onSearch();
                }, 100);

                const timerId = setTimeout(() => {
                    const deliverySummary = (JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY) ?? "") as DeliveryChargeSummaryEntity)
                    const chargeDataSearchCriteria: ChargeDataSearchCriteria = {
                        ...EMPTY_CHARGE_DATA_SEARCH_CRITERIA,
                        handlingTmlList: deliverySummary.handlingTerminal ? [deliverySummary.handlingTerminal] : [],
                        vsl: deliverySummary.vesselCode,
                        voy: deliverySummary.voyageCode,
                        billingCycleCode: deliverySummary.billingCycleCode ?? "",
                        cntrList: JSON.parse(JSON.stringify(deliverySummary.differentCntrNos))

                    };
                    const searchModeEntity: SearchModeEntity = EMPTY_SEARCH_MODE_ENTITY;

                    chargeDataSearchVM.fetchTableData(chargeDataSearchCriteria, searchModeEntity, true).then(data => {
                        chargeDataSearchVM.hideLoading();
                    }).catch(error => {
                        chargeDataSearchVM.hideLoading();
                    }).finally(() => {
                        setInitial(false);
                    });
                }, 300)
                return () => {
                    clearTimeout(timerAddCss);
                    clearTimeout(timerId);
                }
            }
        }
    }, [initinal, chargeDataSearchVM]);

    useEffect(() => {
        chargeDataSearchVM.showLoading();
        const initialScreen = async () => {
            try {
                const results = await Promise.allSettled([
                    chargeDataSearchVM.loadAllSearchPreference('charge-data-search-' + anaInfoState.userName),
                    chargeDataSearchVM.loadDropdownOption(),
                ])
                results?.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } else if (index === 1 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
            }
        }
        initialScreen().then((data) => {
            chargeDataSearchVM.hideLoading();
        }).catch(error => {
            chargeDataSearchVM.hideLoading();
        });
    }, [anaInfoState.userName, chargeDataSearchVM]);

    useEffect(() => {
        if (anaInfoState.userName) {
            chargeDataSearchVM.setUserEmail('charge-data-search-' + anaInfoState.userName)
        }
    }, [chargeDataSearchVM, anaInfoState.userName])

    const memoChargeDataSearchTablePanel = useMemo(() => <ChargeDataSearchTablePanel />, []);
    const memoChargeDataSearchRightPanel = useMemo(() => <ChargeDataSearchRightPanel />, []);

    const memoResizablePanel = useMemo(() =>
        <ResizablePanel
            id={searchCounter === 0 ? 'im-resizable-panel-collapsed' : `im-resizable-panel${!isEditSearchCriteria ? '-active' : ''}`}
            draggable={false}
            leftChildren={memoChargeDataSearchTablePanel}
            rightChildren={memoChargeDataSearchRightPanel}
            rightSectionWidth={searchCounter === 0 ? '100%' : '45%'}
            leftSectionWidth={searchCounter === 0 ? '0%' : '100%'}
            containerWidth={'100%'} />
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [searchCounter, isEditSearchCriteria])

    // Charge Data search event listner
    // const onChargeDataSearchChange = (e: any) => {
    //     switch (e.detail.action) {
    //         case E_Custom_Dispatch_Event.CHARGE_DATA_EDIT_CRITERIA:
    //             chargeDataSearchVM.onEditSearchCriteria();
    //             break;
    //         case E_Custom_Dispatch_Event.CHARGE_DATA_OPEN_DIRECTORY:
    //             chargeDataSearchVM.onSwitchSearchCriteriaMode();
    //             break;
    //         case E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_SAVE_SEARCH:
    //             chargeDataSearchVM.saveSearchPreference(e.detail.data.currentSearchPreference);
    //             break;
    //         case E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_SAVE_NEW:
    //             chargeDataSearchVM.openSaveNewConfirmModal();
    //             break;
    //         case E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_DELETE:
    //             chargeDataSearchVM.openDeleteConfirmModal();
    //             break;
    //         case E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_OPEN:
    //             chargeDataSearchVM.loadSearchPreference(e.detail.data.searchPreference);
    //             break;
    //         // case E_Custom_Dispatch_Event.CHARGE_DATA_MODAL_BACK_TO_LIST:
    //         //     chargeDataSearchVM.closeContainerDetail();
    //         //     break;
    //     }
    // }

    // useEffect(() => {
    //     customEventListener(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, onChargeDataSearchChange);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);


    if (chargeDataSearchState.isLoading) return <Loader Indicator="Stripe" size="Large" />

    return <div className={`charge-data-box${((searchCounter > 0 && !isShowRightCriteriaPanel) || isShowContainerDetail) ? ' no-filter-preview' : ' filter-preview'}${isShowContainerDetail ? ' show-detail' : ''}`}>
        {/* Show container search panel */}
        <div style={{ display: !isShowContainerDetail ? 'block' : 'none' }}>{memoResizablePanel}</div>
        {/* Show container detail page */}
        {/* <div style={{display: !isShowContainerDetail ? 'none' : 'block', height: '100%'}} className='im-inventory-search-confirm-modal-container'>
            {memoContainerDetailModal}
        </div> */}
        {isShowContainerDetail && chargeDataSearchState.openedContainerNo && <MicroFrontend path="charge-management" entrypoint="containerDetail" sectionId={chargeDataSearchState.openedContainerNo} />}

        <div className='im-charge-data-search-confirm-modal-container'>
            <SaveNewPreferenceModal visible={chargeDataSearchState.isShowSaveNewConfirmModal} />
            <DeletePreferenceModal visible={chargeDataSearchState.isShowDeleteConfirmModal} />
        </div>
    </div>;
}