import { CriteriaDirectoryAllCheckboxOption, CriteriaDirectoryCheckboxOptionList } from "presentation/constant/ChargeDataEnquiry/CriteriaDirectoryCheckboxOption";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { useEffect, useMemo, useRef } from "react";
import { HPHCheckbox } from "veronica-ui-component/dist/component/core";

export const ChargeDataSearchCriteriaCheckboxList = () => {
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const chargeDataSearchVM = useChargeDataSearchVM();
    const checkBoxRef = useRef<{ [key: string]: HTMLElement | null }>({});

    useEffect(() => {
        chargeDataSearchVM.updateCheckboxRef(checkBoxRef.current);
    }, [chargeDataSearchVM]);

    const memoCheckBoxList = useMemo(() =>
        <div className='im-charge-data-search-criteria-directory-checkbox-list'>
            <HPHCheckbox label={CriteriaDirectoryAllCheckboxOption.name}
                checked={chargeDataSearchState.checkboxValue && chargeDataSearchState.checkboxValue[CriteriaDirectoryAllCheckboxOption.key]}
                onChange={(e) => chargeDataSearchVM.updateCheckboxValue(e.target.checked, CriteriaDirectoryAllCheckboxOption.key)} />
            {
                CriteriaDirectoryCheckboxOptionList?.map(item => <div key={item.option.key} className='im-charge-data-search-criteria-directory-checkbox-group'>
                    {
                        item.checkboxList?.map(item => <div ref={ref => checkBoxRef.current[item.key] = ref} key={item.key} className={`im-charge-data-search-criteria-directory-checkbox-child ${chargeDataSearchState.highlightedCheckboxKey === item.key ? 'im-charge-data-search-criteria-directory-checkbox-child-highlighted' : ''}`}>
                            <HPHCheckbox label={item.name} checked={chargeDataSearchState.checkboxValue && chargeDataSearchState.checkboxValue[item.key]} onChange={(e) => chargeDataSearchVM.updateCheckboxValue(e.target.checked, item.key)} />
                        </div>)
                    }
                </div>)
            }
        </div>, [chargeDataSearchState.checkboxValue, chargeDataSearchState.highlightedCheckboxKey, chargeDataSearchVM]);

    return memoCheckBoxList;
};
