import { EMPTY_CHARGE_DATA_SEARCH_CRITERIA } from "presentation/constant/ChargeDataEnquiry/ChargeDataSearchCriteria";
import { CriteriaDirectoryDocumentCheckboxOption } from "presentation/constant/ChargeDataEnquiry/CriteriaDirectoryCheckboxOption";
import { SearchCriteriaCheckboxRelatedField } from "presentation/constant/ChargeDataEnquiry/SearchCriteriaCheckboxRelatedField";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { isNonDefaultValue } from "presentation/utils/searchCriteriaUtil";
import { convertDateToDateForComponent } from "presentation/utils/timeUtil";
import { useCallback, useMemo } from "react";
import { HPHGroupTitle, Tags } from "veronica-ui-component/dist/component/core";

export const ChargeDataSearchCriteriaPreviewDocumentPanel = () => {
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const searchCriteria = chargeDataSearchState.searchCriteria;
    const chargeDataSearchVM = useChargeDataSearchVM();
    const enabledCriteria = useCallback(() =>
        CriteriaDirectoryDocumentCheckboxOption.find(item => chargeDataSearchState.enabledSearchCriteria[item.key]),
        [chargeDataSearchState.enabledSearchCriteria])

    const isAllDefaultValue = useCallback(() => {
        const attributeField = CriteriaDirectoryDocumentCheckboxOption?.filter(item => chargeDataSearchState.enabledSearchCriteria[item.key])?.map(item => item.key)
        const isAllDefault = !attributeField.find((field) => {
            return isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, field)
        })
        return isAllDefault
    }, [searchCriteria, chargeDataSearchState.enabledSearchCriteria])

    const handleRemoveSearchCriteira = useCallback((fieldName: string, isSearch?: boolean) => {
        chargeDataSearchVM.showLoading();
        chargeDataSearchVM.onRemoveSearchCriteria(fieldName, isSearch);
        chargeDataSearchVM.hideLoading();
    }, [chargeDataSearchVM]);
    const chargeConstant = ChargeConstant;

    const memoPanel = useMemo(() => <>
        {(enabledCriteria() && !isAllDefaultValue()) ? <div className='im-charge-data-search-criteria-group'>
            <HPHGroupTitle id='im-preview-tag-group-title' titleText='Document' />
            <div className='im-charge-data-search-criteria-preview-tag-group'>
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'invDate') && <Tags
                    label={`${chargeConstant.Charge.INVOICE_DATE_RANGE} : ${convertDateToDateForComponent(searchCriteria.invDateFrom)} - ${convertDateToDateForComponent(searchCriteria.invDateTo)}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('invDate')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'invNo') && <Tags
                    label={`${chargeConstant.Charge.INV_CRE_NOTE_NO} : ${searchCriteria.invNo}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('invNo')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'previewRefNo') && <Tags
                    label={`${chargeConstant.Charge.PREVIEW_REF_NO}: ${searchCriteria.previewRefNo}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('previewRefNo')}
                />}
            </div>
        </div> : null}</>
        , [chargeConstant.Charge.INVOICE_DATE_RANGE, chargeConstant.Charge.INV_CRE_NOTE_NO, chargeConstant.Charge.PREVIEW_REF_NO,
            enabledCriteria, handleRemoveSearchCriteira, isAllDefaultValue, searchCriteria]);

    return memoPanel;
};
